import React, { useContext, useEffect } from 'react';
import Login from "./Login";
import "./../App.css";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
import { ProtectedRoute } from "./../ProtectedRoute";
import Forgotpassword from "./Forgotpassword";
import DailyReport from "./Management/Reports/DailyReport";
import ClaimwiseProduction from "./Management/Reports/ClaimwiseProduction";
import OpenedClaims from "./Management/Reports/OpenedClaims";
import DatabaseSync from "./Data/DatabaseSync";
import DataCleaning from "./Data/DataCleaning";
import ApiSync from "./Data/ApiSync";
import Data from "./Data/Data";
import Admin from "./Admin/Admin";
import UserSubscription from "./Admin/UserSubscription";
import Logout from "./Logout";
import PayerCategory from "./Configure/PayerCategory";
import PayerMaster from "./Configure/PayerMaster";
import PrimaryPayer from "./Configure/PrimaryPayer";
import Ageing from "./Configure/Ageing";
import Cpt from "./Configure/Cpt";
import ARmanagement from "./Management/InventoryAnalysis";
import DenialManagement from "./Transaction/DenialManagement";
import Collection from "./AI/Collection";
import AiBot from "./AI/AiBot";
import ActionManagement from "./Management/Actions";
import Actions from "./Actions/Actions";
import Roles from "./Roles/Roles";
import Performances from "./Performances/Performances";
import tflExceed from "./Transaction/AllocationCardComponents/tflExceed";
import Modifiers from "./Configure/Modifiers";
import ViewClaim from "./Transaction/AllocationCardComponents/ViewClaim";
import ARfollowUpUpdate from "./Transaction/AllocationCardComponents/ARfollowUpUpdate";
import SubCategory from "./Transaction/SubDenial/SubCategory";
import SubDenial from "./Transaction/SubDenial/SubDenial";
import DenialDetails from "./Transaction/SubDenial/DenialDetails";
import Denialallocatedtable from "./Transaction/SubDenial/Denialallocatedtable";
import Denialunallocatedtable from "./Transaction/SubDenial/Denialunallocatedtable";
import Denialtableallocate1 from "./Transaction/SubDenial/Denialtableallocate1";
import Denialtableallocate2 from "./Transaction/SubDenial/Denialtableallocate2";
import Denialtableallocate3 from "./Transaction/SubDenial/Denialtableallocate3";
import Allocated from "./Allocation/Allocated";
import UnAllocated from "./Allocation/UnAllocated";
import FastApproaching from "./Transaction/AllocationCardComponents/FastApproaching";
import TwoTouchClaims from "./Transaction/AllocationCardComponents/TwoTouchClaims";
import ThreeTouchClaims from "./Transaction/AllocationCardComponents/ThreeTouchClaims";
import NotFollowedUpMoreThan30Days from "./Transaction/AllocationCardComponents/NotFollowedUpMoreThan30Days";
import NotFollowUpMoreThan60Days from "./Transaction/AllocationCardComponents/NotFollowUpMoreThan60Days";
import NotFollowUpMoreThan180Days from "./Transaction/AllocationCardComponents/NotFollowUpMoreThan180Days";
import NotFollowUpMoreThan90Days from "./Transaction/AllocationCardComponents/NotFollowUpMoreThan90Days";
import LessThan30daysClaims from "./Transaction/AllocationCardComponents/LessThan30DaysNotFollow";
import DenialClaim from "./Transaction/SubDenial/DenialClaim";
import DataUpload from "./Eligibility/DataUpload";
import  AccessRoute  from "./routepage/AccessRoute";
import DenialMaster from "./Configure/Denialmaster";
import CheckAutoClaim from "./Transaction/AllocationCardComponents/CheckAutoClaim";
import Eligibility from "./Transaction/AllocationCardComponents/Eligibility";
import ActionViewClaim from "./Actions/ActionsComponents/ActionViewClaim";
import ActionEligibility from "./Actions/ActionsComponents/ActionEligibility";
import ActionCheckAutoClaim from "./Actions/ActionsComponents/ActionCheckAutoClaim";
import AgedPaymentCharts from "./Management/TrendsCharts/AgedPaymentCharts";
import ARPrimaryRisk from "./Management/TrendsCharts/ARPrimaryRisk";
import BillingPayment from "./Management/TrendsCharts/BillingPayment";
import TimeGapChart from "./Management/TrendsCharts/TimeGapChart";
import PayerAnalysis from "./Management/TrendsCharts/PayerAnalysis";
import PatientPrimaryAR from "./Management/TrendsCharts/PatientPrimaryAR";
import TopPayers from "./Management/TrendsCharts/TopPayers";
import TopCpt from "./Management/TrendsCharts/TopCpt";
import TopCptPerformance from "./Management/TrendsCharts/TopCptPerformance";
import IncorrectMedicare from "./Management/TrendsCharts/IncorrectMedicare";
import NonMedicare from "./Management/TrendsCharts/NonMedicare";
import MedicareSecondary from "./Management/TrendsCharts/MedicareSecondary";
import AgedAnalysis from "./Management/TrendsCharts/AgedAnalysis";
import ConfigureList from "./Configure/ConfigureList";
import DenialPayment from "./Transaction/SubDenial/DenialPayment";
import UserNeedtocallPage from "./Actions/UserNeedtocallPage";
import TrendsPatterns from "./Management/TrendsPatterns";
import CardAlloc from "./Transaction/CardAlloc";
import UserCompletedPage from "./Actions/UserCompletedPage";
import UserInprogressPage from "./Actions/UserInprogressPage";
import UserDenialPage from "./Actions/UserDenialPage";
import BillingProvider from "./Configure/BillingProvider";
import AdvBillingProvider from "./Configure/AdvBillingProv";
import UserDetails from "./Configure/UserDetails";
import Twilio from "./Transaction/Twilio";
import FourOrMoreTouchClaims from "./Transaction/AllocationCardComponents/FourOrMoreTouch";
import LowFollowupClaims from "./Transaction/AllocationCardComponents/LowFollowup";
import LessThan180daysClaims from "./Transaction/AllocationCardComponents/LessThan180DaysClaims";
import LessThan30DaysNotFollow from "./Transaction/AllocationCardComponents/LessThan30DaysNotFollow";
import PerformanceDashboard from "./Management/Outcomes";
import HealthScorecard from "./Management/IndustryStandards";
import SubCategory1 from "./Transaction/SubDenial/SubCategory1";
import SubCategory2 from "./Transaction/SubDenial/SubCategory2";
import SubCategory3 from "./Transaction/SubDenial/SubCategory3";
import SubCategory4 from "./Transaction/SubDenial/SubCategory4";
import SubCategory5 from "./Transaction/SubDenial/SubCategory5";
import SubCategory6 from "./Transaction/SubDenial/SubCategory6";
import SubCategory7 from "./Transaction/SubDenial/SubCategory7";
import SubCategory8 from "./Transaction/SubDenial/SubCategory8";
import SubCategory9 from "./Transaction/SubDenial/SubCategory9";
import ManagementUserPerformanceDashboard from "./Management/ManagementUserPerformanceDashboard";
import DenialPrediction from "./AI/DenialPrediction";
import ClaimManagement from "./Transaction/ClaimManagement";
import Acknowledgements from "./Transaction/ClaimManagement/Acknowledgements";
import AdditionalInformation from "./Transaction/ClaimManagement/AdditionalInformation";
import DataReporting from "./Transaction/ClaimManagement/DataReporting";
import Finalized from "./Transaction/ClaimManagement/Finalized";
import Pending from "./Transaction/ClaimManagement/Pending";
import Errors from "./Transaction/ClaimManagement/Errors";
import Searches from "./Transaction/ClaimManagement/Machine1Searches";
import ClaimSubCategoryDetails from "./Transaction/ClaimManagement/ClaimSubCategoryDetails";
import DenialPredictionDashboard from "./AI/DenialPredictionDashboard";
import CollectionPredictionDashboard from "./AI/CollectionPredictionDashboard";
import ClaimStatus from "./Configure/ClaimStatus";
import ClaimCategory from "./Configure/ClaimCategory";
import ClaimSubCategory from "./Configure/ClaimSubCategory";
import ActionCode from "./Configure/ActionCode";
import StatusActionCode from "./Configure/StatusActionCode";
import DataConversionOCR from "./Data/DataConversionOCR";
import WebClaims from "./Claims/WebClaims";
import IVRClaims from "./Claims/IVRClaims";
import ManualClaims from "./Claims/ManualClaims";
import ClientOnboard from "./SuperAdmin/ClientOnboard";
import { clientNotes } from "./Configure/clientNotes";
import Locations from "./Configure/Locations";
import Reports from "./Management/Reports";
import UserNotTouchPage from "./Actions/UserNotTouchPage";
import Rejection from "./Allocation/Rejection";
import ActionRejection from "./Actions/ActionRejection";
import OpenClaims from "./Actions/OpenClaims";
import ClosedClaims from "./Actions/ClosedClaims";
import DataMigration from "./Data/DataMigration";
import Claimsubcategoryunallocate1 from "./Transaction/ClaimManagement/Claimsubcategoryunallocate1";
import Claimtablenottouchweb1 from "./Transaction/ClaimManagement/Claimtablenottouchweb1";
import Claimtablenottouchweb2 from "./Transaction/ClaimManagement/Claimtablenottouchweb2";
import Claimtablenottouchweb3 from "./Transaction/ClaimManagement/Claimtablenottouchweb3";
import AgeGreaterThan365days from "./Transaction/AllocationCardComponents/AgeGreaterThan365days";
import AgeBetween181to365Days from "./Transaction/AllocationCardComponents/AgeBetween181to365Days";
import AgeBetween91to180Days from "./Transaction/AllocationCardComponents/AgeBetween91to180Days";
import AgeBetween61to90Days from "./Transaction/AllocationCardComponents/AgeBetween61and90Days";
import AgeBetween31to60Days from "./Transaction/AllocationCardComponents/AgeBetween31to60Days";
import OneTouchClaims from "./Transaction/AllocationCardComponents/OneTouchClaims";
import NotTouchClaims from "./Transaction/AllocationCardComponents/NotTouchClaims";
import FourTouchClaims from "./Transaction/AllocationCardComponents/FourTouchClaims";
import OutcomeData from "./Eligibility/OutcomeData";
import Viewmoreeligibility from "./Eligibility/Viewmoreeligibility";
import ActionBucket from "./Management/ActionBucket";
import KeyUnallocated from "./Transaction/AllocationCardComponents/KeyUnallocated";
import Keyallocated from "./Transaction/AllocationCardComponents/Keyallocated";
import Actionunallocated from "./Actions/Actionunallocated";
import Actionallocated1 from "./Management/Actionallocated1";
import Automationtrack from "./Admin/Automationtrack/Automationtrack";
import Automationatage30 from "./Admin/Automationtrack/Automationatage30";
import Searchanderror from "./Admin/Automationtrack/Searchanderror";
import Uploadautomation from "./Admin/Automationtrack/Uploadautomation";
import Nextfollow from "./Admin/Automationtrack/Nextfollow";
import Keyallocated1 from "./Transaction/AllocationCardComponents/Keyallocated1";
import Keyallocated2 from "./Transaction/AllocationCardComponents/Keyallocated2";
import Actionallocated3 from "./Management/Actionallocated3";
import Actionallocated2 from "./Management/Actionallocated2";
import Authorization from "./Eligibility/Authorization";
import EligibilityCheck from "./Eligibility/EligibilityCheck";
import AuthForm from "./Eligibility/AuthForm";
import PriorAuth from "./Eligibility/PriorAuth";
import Icd from "./Configure/Icd";
import RefProvider from "./Configure/RefProvider";
import Updates from "./Configure/Updates";
import EditForm from "./Eligibility/EditForm";
import WebclaimAllocate from "./Claims/WebclaimAllocate";
import IvrclaimAllocate from "./Claims/IvrclaimAllocate";
import ManualclaimAllocate from "./Claims/ManualclaimAllocate";
import Unallocatedclaim from "./Claims/Unallocateclaim";
import ChangePassword from "./ChangePassword";
import setQues from "./setQues";
import DataDelete from "./Data/DataDelete";
import UnpaidClaims from "./Management/TrendsCharts/Unpaidclaims";
import Errors2 from "./Transaction/ClaimManagement/Machine2Error";
import Searches2 from "./Transaction/ClaimManagement/Machine2Searches";
import GeneralSettings from "./Configure/GeneralSettings";
import RejectionUpload from "./Configure/RejectionUpload";
import AdvancedMD from "./Data/AdvancedMD";
import Machine1Error from "./Transaction/ClaimManagement/Machine1Error";
import Machine1Searches from "./Transaction/ClaimManagement/Machine1Searches";
import Machine2Error from "./Transaction/ClaimManagement/Machine2Error";
import Machine2Searches from "./Transaction/ClaimManagement/Machine2Searches";
import noteType from "./Configure/noteType";
import {  UserActivityProvider } from './Utilities/UserActivityProvider';
import PageTracker from './Utilities/PageTracker';
import ClaimCheck from './Management/ClaimCheck';
import { UploadProvider } from './Dashboard/UploadContext';
import { io } from 'socket.io-client';
function App() {
  useEffect(() => {
    // Replace this with your backend's WebSocket URL
    const socket = io('wss://apidemorevshield.imagnumhealthcare.com/socket.io', {
        transports: ['websocket'], // Forces WebSocket transport
        secure: true,              // Ensures it's using WSS (WebSocket Secure)
    });

    socket.on('connect', () => {
        console.log('Connected to WebSocket');
    });

    socket.on('disconnect', () => {
        console.log('Disconnected from WebSocket');
    });

    // Clean up the connection on unmount
    return () => {
        socket.disconnect();
    };
}, []);

  return (
    <UploadProvider>
    <div className='App'>
      <BrowserRouter>
        <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/logout" component={Logout} />
          <Route path="/forgotpassword" component={Forgotpassword} />
          <AccessRoute
            path="/claim-management"
            component={ClaimManagement}  
          />
          <AccessRoute
            path="/single-claim-check"
            component={ClaimCheck}
            
          />
          <AccessRoute
            path="/client-onboard"
            component={ClientOnboard}
            
          />
          <AccessRoute
            path="/claim/acknowledgements"
            component={Acknowledgements}
            
          />
          <AccessRoute
            path="/claim/data reporting acknowledgements"
            component={DataReporting}
            
          />
          <AccessRoute
            path="/claim/finalized"
            component={Finalized}
            
          />
          <AccessRoute
            path="/claim/pending"
            component={Pending}
            
          />
          <AccessRoute
            path="/claim/requests for additional information"
            component={AdditionalInformation}
            
          />
          <AccessRoute
            path="/claim/error"
            component={Errors}
            
          />
          <AccessRoute
            path="/error/machine1 error"
            component={Machine1Error}
            
          />
          <AccessRoute
            path="/error/machine1 searches"
            component={Machine1Searches}
            
          />
          <AccessRoute
            path="/error/machine2 Error"
            component={Machine2Error}
            
          />
          <AccessRoute
            path="/error/machine2 Searches"
            component={Machine2Searches}
            
          />
          {/* <AccessRoute
            path="/claim/availity error"
            component={Errors2}
            
          /> */}

          <AccessRoute path="/twilio" component={Twilio}  />
          <AccessRoute
            path="/claim/searches"
            component={Searches}
            
          />
          {/* <AccessRoute
            path="/claim/availity searches"
            component={Searches2}
            
          /> */}
          <AccessRoute
            path="/claim/sub-category-details"
            component={ClaimSubCategoryDetails}
            
          />

          <AccessRoute
            path="/claim/availity error"
            component={Errors2}
            
          />
          <AccessRoute
            path="/claim/availity searches"
            component={Searches2}
            
          />
          <AccessRoute
            path="/management-outcomes"
            component={PerformanceDashboard}
            
          ></AccessRoute>
          <AccessRoute
            path="/management-actions"
            component={ActionManagement}
            
          ></AccessRoute>
          {/* <AccessRoute path="/management-reports" component={ManagementReports} role={[1,3]}></AccessRoute> */}
          <AccessRoute
            path="/management-industry-standards"
            component={HealthScorecard}
            
          ></AccessRoute>
          {/* <AccessRoute path="/management-rejection-analysis" component={RejectionAnalysis} role={[1,3]}></AccessRoute>
          <AccessRoute path="/management-denial-analysis" component={ManagementDenialAnalysis} role={[1,3]}></AccessRoute> */}
          <AccessRoute
            path="/management-userperformance-dashboard"
            component={ManagementUserPerformanceDashboard}
            
          ></AccessRoute>
          {/* <AccessRoute path="/management-billing-scorecard" component={BillingScorecard} role={[1,3]}></AccessRoute> */}
          <AccessRoute
            path="/management-inventory-analysis"
            component={ARmanagement}
            
          />
          <AccessRoute
            path="/action-bucket"
            component={ActionBucket}
            
          />
          <AccessRoute
            path="/generate-reports"
            component={Reports}
            
          />

          <AccessRoute
            path="/daily-report-prodution"
            component={{ DailyReport }}
            
          />
          <AccessRoute
            path="/automation-at-age-30"
            component={{ Automationatage30 }}
            
          />
          <AccessRoute
            path="/claim wise-production"
            component={{ ClaimwiseProduction }}
            
          />
          <AccessRoute
            path="/opened-claims"
            component={{ OpenedClaims }}
            
          />
          <AccessRoute
            path="/denial-prediction"
            component={DenialPrediction}
            
          />
          <AccessRoute
            path="/denial-prediction-dashboard"
            component={DenialPredictionDashboard}
            
          />
          <AccessRoute
            path="/collection-prediction-dashboard"
            component={CollectionPredictionDashboard}
            
          />
          <AccessRoute
            path="/denial-management"
            component={DenialManagement}
            
          />
          <AccessRoute
            path="/allocation-buckets"
            component={CardAlloc}
            
          />
          <AccessRoute path="/data" component={Data}  />
          <AccessRoute
            path="/claims/Web"
            component={WebClaims}
            
          />
          <AccessRoute
            path="/Web/Allocate"
            component={WebclaimAllocate}
            
          />
          <AccessRoute
            path="/IVR/Allocate"
            component={IvrclaimAllocate}
            
          />
          <AccessRoute
            path="/Manual/Allocate"
            component={ManualclaimAllocate}
            
          />
          <AccessRoute
            path="/unallocatedclaims"
            component={Unallocatedclaim}
            
          />

          <AccessRoute
            path="/claims/IVR"
            component={IVRClaims}
            
          />
          <AccessRoute
            path="/claims/Manual"
            component={ManualClaims}
            
          />
          <AccessRoute
            path="/eligibility/Dataupload"
            component={DataUpload}
            
          />
          <AccessRoute
            path="/eligibility/Outcomedata"
            component={OutcomeData}
            
          />

          <AccessRoute
            path="/eligibility/Auth"
            component={Authorization}
            
          />
          <AccessRoute
            path="/eligibility/PriorAuth"
            component={PriorAuth}
            
          />
          <AccessRoute
            path="/eligibility/AuthForm"
            component={AuthForm}
            
          />
          <AccessRoute
            path="/eligibility/EditForm"
            component={EditForm}
            
          />
          <AccessRoute
            path="/eligibility/Check"
            component={EligibilityCheck}
            
          />
          <AccessRoute
            path="/allocate/rejection"
            component={Rejection}
            
          />

          <AccessRoute
            path="/dataconversionocr"
            component={DataConversionOCR}
            
          />
          <AccessRoute
            path="/dataCleaning"
            component={DataCleaning}
            
          />
          <AccessRoute
            path="/dataDeleting"
            component={DataDelete}
            
          />
          <AccessRoute
            path="/datamigration"
            component={DataMigration}
            
          />
          <AccessRoute
            path="/admin/useronboard"
            component={Admin}
            
          />
          <AccessRoute
            path="/admin/usersubscription"
            component={UserSubscription}
            
          />
          <AccessRoute
            path="/database-sync"
            component={DatabaseSync}
            
          />
          <AccessRoute path="/api-sync" component={ApiSync}  />
          <AccessRoute path="/apisync/advancedmd" component={AdvancedMD}  />
          <AccessRoute
            path="/configure/claim-subcategory"
            component={ClaimSubCategory}
            
          />
          <AccessRoute
            path="/configure/action-code"
            component={ActionCode}
            
          />
          <AccessRoute
            path="/configure/note-types"
            component={noteType}
            
          />
          <AccessRoute
            path="/configure/status-action-code"
            component={StatusActionCode}
            
          />
          <AccessRoute
            path="/configure/claim-category"
            component={ClaimCategory}
            
          />
          <AccessRoute
            path="/configure/claim-status"
            component={ClaimStatus}
            
          />
          <AccessRoute
            path="/configure/client-notes"
            component={clientNotes}
            
          />
          <AccessRoute
            path="/configure/general-settings"
            component={GeneralSettings}
            
          />
          <AccessRoute
            path="/configure/self-upload"
            component={RejectionUpload}
            
          />
          <AccessRoute
            path="/configure/location"
            component={Locations}
            
          />
          <AccessRoute path="/configure/icd" component={Icd}  />
          <AccessRoute path="/configure/icd" component={Icd}  />
          <AccessRoute
            path="/configure/refprovider"
            component={RefProvider}
            
          />
          <AccessRoute
            path="/configure/newlyupdates"
            component={Updates}
            
          />
          <AccessRoute
            path="/configure/payer-category"
            component={PayerCategory}
            
          />
          <AccessRoute
            path="/configure/payer-master"
            component={PayerMaster}
            
          />
          <AccessRoute
            path="/configure/primary-payer"
            component={PrimaryPayer}
            
          />
          <AccessRoute path="/configure/cpt" component={Cpt}  />
          <AccessRoute
            path="/configure/modifiers"
            component={Modifiers}
            
          />
          <AccessRoute
            path="/configure/denial-master"
            component={DenialMaster}
            
          />
          <AccessRoute
            path="/configure/configure-list"
            component={ConfigureList}
            
          />
          <AccessRoute
            path="/configure/ageing"
            component={Ageing}
            
          />
          <AccessRoute
            path="/performances"
            component={Performances}
            
          />
          <AccessRoute
            path="/change_password"
            component={ChangePassword}
            
          />
          <AccessRoute path="/setAnswers" component={setQues}  />
          <AccessRoute path="/actions" component={Actions}  />
          <AccessRoute
            path="/action-view-claim"
            component={ActionViewClaim}
            
          />
          <AccessRoute
            path="/action-rejection-denials"
            component={ActionRejection}
            
          />
          <AccessRoute
            path="/open-claims"
            component={OpenClaims}
            
          />
          <AccessRoute
            path="/closed-claims"
            component={ClosedClaims}
            
          />
          <AccessRoute
            path="/keyindicatorallocated"
            component={Keyallocated}
            
          />
          <AccessRoute
            path="/actionunallocated"
            component={Actionunallocated}
            
          />
          <AccessRoute
            path="/actionallocated1"
            component={Actionallocated1}
            
          />
          <AccessRoute
            path="/actionallocated2"
            component={Actionallocated2}
            
          />
          <AccessRoute
            path="/actionallocated3"
            component={Actionallocated3}
            
          />
          <AccessRoute
            path="/keyindicatorallocated1"
            component={Keyallocated1}
            
          />
          <AccessRoute
            path="/keyindicatorallocated2"
            component={Keyallocated2}
            
          />
          <AccessRoute
            path="/action-eligibility"
            component={ActionEligibility}
            
          />
          <AccessRoute
            path="/action-check-auto-claim"
            component={ActionCheckAutoClaim}
            
          />
          <AccessRoute
            path="/user-completed-page"
            component={UserCompletedPage}
            
          />
          <AccessRoute
            path="/user-inprogress-page"
            component={UserInprogressPage}
            
          />
          <AccessRoute
            path="/user-denial-page"
            component={UserDenialPage}
            
          />
          <AccessRoute
            path="/user-nottouch-page"
            component={UserNotTouchPage}
            
          />
          <AccessRoute
            path="/user-needtocall-page"
            component={UserNeedtocallPage}
            
          />
          <AccessRoute path="/roles" component={Roles}  />
          <AccessRoute path="/ai-bot" component={AiBot}  />
          <AccessRoute
            path="/TFLexceed"
            component={tflExceed}
            
          />

          <AccessRoute
            path="/Keyindicatorunallocate"
            component={KeyUnallocated}
            
          />

          <AccessRoute
            path="/Fast-Approaching"
            component={FastApproaching}
            
          />
          <AccessRoute
            path="/TwoTouch-Claims"
            component={TwoTouchClaims}
            
          />
          <AccessRoute
            path="/ThreeTouch-Claims"
            component={ThreeTouchClaims}
            
          />
          <AccessRoute
            path="/four-or-more-touch"
            component={FourOrMoreTouchClaims}
            
          />
          <AccessRoute
            path="/lowfollow-up"
            component={LowFollowupClaims}
            
          />
          <AccessRoute
            path="/less-than-180days-claims"
            component={LessThan180daysClaims}
            
          />
          <AccessRoute
            path="/more-than-60days-notfollow"
            component={NotFollowUpMoreThan60Days}
            
          />
          <AccessRoute
            path="/more-than-180days-notfollow"
            component={NotFollowUpMoreThan180Days}
            
          />
          <AccessRoute
            path="/more-than-90days-notfollow"
            component={NotFollowUpMoreThan90Days}
            
          />
          <AccessRoute
            path="/more-than-30days-notfollow"
            component={NotFollowedUpMoreThan30Days}
            
          />
          <AccessRoute
            path="/less-than-30days-NotFollow"
            component={LessThan30DaysNotFollow}
            
          />
          <AccessRoute
            path="/agegreaterthan365days"
            component={AgeGreaterThan365days}
            
          />
          <AccessRoute
            path="/age-between-181-365-days"
            component={AgeBetween181to365Days}
            
          />
          <AccessRoute
            path="/age-between-91-180-days"
            component={AgeBetween91to180Days}
            
          />
          <AccessRoute
            path="/age-between-61-90-days"
            component={AgeBetween61to90Days}
            
          />
          <AccessRoute
            path="/age-between-31-60-days"
            component={AgeBetween31to60Days}
            
          />
          <AccessRoute
            path="/one-touch-claims"
            component={OneTouchClaims}
            
          />
          <AccessRoute
            path="/zero-touch-claims"
            component={NotTouchClaims}
            
          />
          <AccessRoute
            path="/four-touch-claims"
            component={FourTouchClaims}
            
          />
          <AccessRoute
            path="/view-claim"
            component={ViewClaim}
            
          />
          {/* <AccessRoute path="/manage-payment" component={ManagePayment}/> */}
          <AccessRoute
            path="/ar-follow-up-update"
            component={ARfollowUpUpdate}
            
          />
          <AccessRoute
            path="/eligibility"
            component={Eligibility}
            
          />
          <AccessRoute
            path="/viewmoreeligibility"
            component={Viewmoreeligibility}
            
          />

          <AccessRoute
            path="/management-trends-patterns"
            component={TrendsPatterns}
            
          />
          <AccessRoute
            path="/trends/aged-payment-charts"
            component={AgedPaymentCharts}
            
          />
          <AccessRoute
            path="/trends/Unpaidclaims"
            component={UnpaidClaims}
            
          />
          <AccessRoute
            path="/trends/primary-risk"
            component={ARPrimaryRisk}
            
          />
          <AccessRoute
            path="/trends/billing-payment"
            component={BillingPayment}
            
          />
          <AccessRoute
            path="/trends/time-gap-dos"
            component={TimeGapChart}
            
          />
          <AccessRoute
            path="/trends/payer-analysis"
            component={PayerAnalysis}
            
          />
          <AccessRoute
            path="/trends/patient-primary-ar"
            component={PatientPrimaryAR}
            
          />
          <AccessRoute
            path="/trends/top10-cpt"
            component={TopCpt}
            
          />
          <AccessRoute
            path="/trends/top10-payers"
            component={TopPayers}
            
          />
          {/* <AccessRoute
            path="/trends/incorrect medicare writeoff"
            component={IncorrectMedicare}
            
          />
          <AccessRoute
            path="/trends/non-medicare claims"
            component={NonMedicare}
            
          />
          <AccessRoute
            path="/trends/medicare secondary pending"
            component={MedicareSecondary}
            
          /> */}
          <AccessRoute
            path="/trends/top-cpt-performance"
            component={TopCptPerformance}
            
          />
          <AccessRoute
            path="/trends/aged-payer-analysis"
            component={AgedAnalysis}
            
          />
          <AccessRoute
            path="/denial/category"
            component={SubDenial}
            
          />
          <AccessRoute
            path="/denial/sub-category"
            component={SubCategory}
            
          />
          <AccessRoute
            path="/denial/sub-category1"
            component={SubCategory1}
            
          />
          <AccessRoute
            path="/denial/sub-category2"
            component={SubCategory2}
            
          />
          <AccessRoute
            path="/denial/sub-category3"
            component={SubCategory3}
            
          />
          <AccessRoute
            path="/denial/sub-category4"
            component={SubCategory4}
            
          />
          <AccessRoute
            path="/denial/sub-category5"
            component={SubCategory5}
            
          />
          <AccessRoute
            path="/denial/sub-category6"
            component={SubCategory6}
            
          />
          <AccessRoute
            path="/denial/sub-category7"
            component={SubCategory7}
            
          />
          <AccessRoute
            path="/denial/sub-category8"
            component={SubCategory8}
            
          />
          <AccessRoute
            path="/denial/sub-category9"
            component={SubCategory9}
            
          />

          <AccessRoute
            path="/denial/denial-details"
            component={DenialDetails}
            
          />
          <AccessRoute
            path="/Transaction/SubDenial/Denialallocatedtable"
            component={Denialallocatedtable}
            
          />
          <AccessRoute
            path="/Transaction/SubDenial/Denialunallocatedtable"
            component={Denialunallocatedtable}
            
          />
          <AccessRoute
            path="/Transaction/SubDenial/Denialtableallocate1"
            component={Denialtableallocate1}
            
          />
          <AccessRoute
            path="/Transaction/SubDenial/Denialtableallocate2"
            component={Denialtableallocate2}
            
          />
          <AccessRoute
            path="/Transaction/SubDenial/Denialtableallocate3"
            component={Denialtableallocate3}
            
          />
          <AccessRoute
            path="/automation-track"
            component={Automationtrack}
            
          />

          <AccessRoute
            path="/search and error automation"
            component={Searchanderror}
            
          />
          <AccessRoute
            path="/upload automation"
            component={Uploadautomation}
            
          />
          <AccessRoute
            path="/next follow automation"
            component={Nextfollow}
            
          />
          <AccessRoute
            path="/allocate/allocated"
            component={Allocated}
            
          />
          <AccessRoute
            path="/allocate/unallocated"
            component={UnAllocated}
            
          />

          <AccessRoute
            path="/Transaction/ClaimManagement/Claimsubcategoryunallocate1"
            component={Claimsubcategoryunallocate1}
            
          />

          <AccessRoute
            path="/Transaction/ClaimManagement/Claimtablenottouchweb1"
            component={Claimtablenottouchweb1}
            
          />
          <AccessRoute
            path="/Transaction/ClaimManagement/Claimtablenottouchweb2"
            component={Claimtablenottouchweb2}
            
          />
          <AccessRoute
            path="/Transaction/ClaimManagement/Claimtablenottouchweb3"
            component={Claimtablenottouchweb3}
            
          />

          <AccessRoute
            path="/denialclaim"
            component={DenialClaim}
            
          />
          <AccessRoute
            path="/denial-payment"
            component={DenialPayment}
            
          />
          <AccessRoute
            path="/configure/denial-master"
            component={DenialMaster}
            
          />
          <AccessRoute
            path="/check-auto-claim"
            component={CheckAutoClaim}
            
          />
          <AccessRoute
            path="/configure/billing-provider"
            component={BillingProvider}
            
          />
          <AccessRoute
            path="/configure/advance-billing-provider"
            component={AdvBillingProvider}
            
          />
          <AccessRoute
            path="/configure/user-details"
            component={UserDetails}
            
          />

          <Redirect to="/logout" />
        </Switch>
      </BrowserRouter>
    </div>
    </UploadProvider>
  );
}

export default App;
