import React, { useState, useEffect } from "react";
import { BiUser, BiLogOut } from "react-icons/bi";
import { IoIdCardOutline } from "react-icons/io5";
import { BsPhone } from "react-icons/bs";
import { AiOutlineMail, AiOutlineTeam } from "react-icons/ai";
import "./Newsidebar.css";
import obo from "../../Images/Imglogo.png";
import axios from "axios";
import { ip } from "../Api";
import "../../Styles/header.css";
import { NavLink } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { useSocket } from "../Context/socketContext";
const Newsidebar = ({ name, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFeaturesOpen, setIsFeaturesOpen] = useState(false);
  const [isProfileOpen, setIsprofileOpen] = useState(false);
  const [role, setRole] = useState("");
  const [userid, setUserid] = useState("");
  const [phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [apisync, setApisync] = useState("");
  const [claimtracker, setClaimtracker] = useState(false);
  const [clientname, setclientname] = useState("");
  const [rolenames, setRolenames] = useState([]);
  const [access, setAccess] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { isVisible, toggleCard, progress } = useSocket();
  const {
    notInserted,
    inserted,
    totalCount,
    duration,
    duplicate,
    fileName,
    queuing,
    progressCount,
  } = useSocket();
  const getrole = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("accesstoken")}`, // Assuming accesstoken is stored in sessionStorage
    };
    try {
      const res = await axios.get(`${ip}/role/all`, { headers: headers });
      const fetchedRolenames = res.data.role.map((data) => data.role_name);
      sessionStorage.setItem(
        "getRolenamesdata",
        JSON.stringify(fetchedRolenames)
      );
      setRolenames(fetchedRolenames);
      setAccess(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getrole().then(() => setLoaded(true));
  }, []);

  const containerClass = rolenames.includes(role)
    ? "container-fluid admin"
    : "container-fluid user";

  const ToggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const roleAccessData = JSON.parse(sessionStorage.getItem("role_access"));

  const [subMenuStates, setSubMenuStates] = useState({
    "Menu Item 1": false,
    "Menu Item 4": false,
    "Menu Item 2": false,
    "Menu Item 3": false,
    "Menu Item 5": false,
    "Menu Item 6": false,
    "Menu Item 7": false,
    //for user side claim tracker
    "Menu Item 8": false,
    // Add more submenu states as needed
  });

  const toggleSubMenu = (menuItem) => {
    const updatedStates = Object.keys(subMenuStates).reduce((acc, key) => {
      acc[key] = key === menuItem ? !subMenuStates[menuItem] : false;
      return acc;
    }, {});
    setSubMenuStates(updatedStates);
  };

  const ToggleSidebar = () => {
    setIsprofileOpen(!isProfileOpen);
  };
  // Retrieve role from sessionStorage on component mount
  useEffect(() => {
    const userRole = sessionStorage.getItem("role_type");
    if (userRole) {
      setRole(userRole);
    }
  }, []);
  //Retrieve user id from the sessionStorage on component mount
  useEffect(() => {
    const userId = sessionStorage.getItem("user_id");
    if (userId) {
      setUserid(userId);
    }
  }, []);
  //Retrieve client name from the sessionStorage on component mount
  useEffect(() => {
    const clientname = sessionStorage.getItem("clientname");
    if (clientname) {
      setclientname(clientname);
    }
  }, []);
  //Retrieve phone from the sessionStorage on component mount
  useEffect(() => {
    const Phone = sessionStorage.getItem("phone");
    if (Phone) {
      setPhone(Phone);
    }
  }, []);
  //Retrieve email from the sessionStorage on component mount
  useEffect(() => {
    const Email = sessionStorage.getItem("email_id");
    if (Email) {
      setEmail(Email);
    }
  }, []);
  //Retrieve username from the sessionStorage on component mount
  useEffect(() => {
    const username =
      sessionStorage.getItem("firstname") +
      " " +
      sessionStorage.getItem("lastname");
    if (username) {
      setUsername(username);
    }
  }, []);
  //Retrieve username from the sessionStorage on component mount
  useEffect(() => {
    const apisync = sessionStorage.getItem("api-sync");
    if (apisync) {
      setApisync(apisync);
    }
  }, []);
  //Retrieve claimtracker from the sessionStorage on user side component mount
  useEffect(() => {
    const claimtracker = sessionStorage.getItem("claim_tracker");
    // Convert the string value from sessionStorage to a boolean
    if (claimtracker === "true") {
      setClaimtracker(true);
    } else {
      setClaimtracker(false);
    }
  }, []);
  return (
    <nav className="navbar navbar-expand-lg">
      <div className={containerClass}>
        <div className="header-container">
          <section className="animation">
            <div className="first">
              <div>
                <img style={{ width: "100px" }} src={obo} alt="obo" />
              </div>
            </div>
            <div className="second">
              {" "}
              <div>
                <h4 className="size">Rev Shield A.I</h4>
              </div>
            </div>
            <div className="third">
              <div>
                <img style={{ width: "100px" }} src={obo} alt="obo" />
              </div>
            </div>
          </section>
        </div>
        <button className="navbar-toggler" type="button" onClick={ToggleNavbar}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}>
          {rolenames.includes(role) ? (
            <div>
              <ul className="navbar-nav ml-auto">
                {roleAccessData.management && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => toggleSubMenu("Menu Item 1")}
                    >
                      Management
                      {subMenuStates["Menu Item 1"] ? (
                        <span>&#9650;</span>
                      ) : (
                        <span>&#9660;</span>
                      )}
                    </a>
                    <ul
                      className={`dropdown-menu ${
                        subMenuStates["Menu Item 1"] ? "show" : ""
                      }`}
                    >
                      {roleAccessData.inventory && (
                        <li>
                          <NavLink
                            to="/management-inventory-analysis"
                            className="linksidebar dropdown-item"
                          >
                            Inventory Analysis
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.outcome && (
                        <li>
                          <NavLink
                            to="/management-outcomes"
                            className="linksidebar dropdown-item"
                          >
                            Outcomes
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.action && (
                        <li>
                          <NavLink
                            to="/management-actions"
                            className="linksidebar dropdown-item"
                          >
                            Actions
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.industryStandards && (
                        <li>
                          <NavLink
                            to="/management-industry-standards"
                            className="linksidebar dropdown-item"
                          >
                            {" "}
                            Industry Standards
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.adminUserperformanceDashboard && (
                        <li>
                          <NavLink
                            to="/management-userperformance-dashboard"
                            className="linksidebar dropdown-item"
                          >
                            {" "}
                            Userperformance Dashboard
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.trendsAndPatterns && (
                        <li>
                          <NavLink
                            to="/management-trends-patterns"
                            className="linksidebar dropdown-item"
                          >
                            Trends & Patterns
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.reports && (
                        <li>
                          <NavLink
                            to="/generate-reports"
                            className="linksidebar dropdown-item"
                          >
                            Reports
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.claimQuickCheck && (
                        <li>
                          <NavLink
                            to="/single-claim-check"
                            className="linksidebar dropdown-item"
                          >
                            Claim Quick Check
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                {roleAccessData.claims && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#"
                      onClick={() => toggleSubMenu("Menu Item 2")}
                    >
                      Claims{" "}
                      {subMenuStates["Menu Item 2"] ? (
                        <span>&#9650;</span>
                      ) : (
                        <span>&#9660;</span>
                      )}
                    </a>
                    <ul
                      className={`dropdown-menu ${
                        subMenuStates["Menu Item 2"] ? "show" : ""
                      }`}
                    >
                      {roleAccessData.keyIndicators && (
                        <li>
                          <NavLink
                            to="/allocation-buckets"
                            className="linksidebar"
                          >
                            Key Indicators
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.denialManagement && (
                        <li>
                          <NavLink
                            to="/denial-management"
                            className="linksidebar"
                          >
                            Denial Management
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.claimManagement && (
                        <li>
                          <NavLink
                            to="/claim-management"
                            className="linksidebar"
                          >
                            {" "}
                            Claim Management
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.automationTracker && (
                        <li>
                          <NavLink
                            to="/automation-track"
                            className="linksidebar"
                          >
                            {" "}
                            Automation track
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                {roleAccessData.dataProcessing && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => toggleSubMenu("Menu Item 3")}
                    >
                      Data Processing{" "}
                      {subMenuStates["Menu Item 3"] ? (
                        <span>&#9650;</span>
                      ) : (
                        <span>&#9660;</span>
                      )}
                    </a>
                    <ul
                      className={`dropdown-menu ${
                        subMenuStates["Menu Item 3"] ? "show" : ""
                      }`}
                    >
                      {roleAccessData.databaseSync && (
                        <li>
                          <NavLink to="/database-sync" className="linksidebar">
                            {" "}
                            Database Sync
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.apiSync && (
                        <li>
                          {apisync > 0 && role === "Admin" ? (
                            <li>
                              <NavLink to="/api-sync" className="linksidebar">
                                Api Sync
                              </NavLink>
                            </li>
                          ) : null}
                        </li>
                      )}
                      {roleAccessData.dataUploadFile && (
                        <li>
                          <NavLink to="/data" className="linksidebar">
                            {" "}
                            Data UploadFile{" "}
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.dataDeleteFile && (
                        <li>
                          <NavLink to="/dataDeleting" className="linksidebar">
                            {" "}
                            Data DeleteFile{" "}
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.dataConversionOcr && (
                        <li>
                          <NavLink
                            to="/dataconversionocr"
                            className="linksidebar"
                          >
                            {" "}
                            Data ConversionOCR
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.dataCleaning && (
                        <li>
                          <NavLink to="/datacleaning" className="linksidebar">
                            {" "}
                            Data Cleaning
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.dataMigration && (
                        <li>
                          <NavLink to="/datamigration" className="linksidebar">
                            {" "}
                            Data Migration
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                {roleAccessData.AIPrediction && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => toggleSubMenu("Menu Item 4")}
                    >
                      AI Prediction{" "}
                      {subMenuStates["Menu Item 4"] ? (
                        <span>&#9650;</span>
                      ) : (
                        <span>&#9660;</span>
                      )}
                    </a>
                    <ul
                      className={`dropdown-menu ${
                        subMenuStates["Menu Item 4"] ? "show" : ""
                      }`}
                    >
                      {roleAccessData.collectionPredictionDashboard && (
                        <li>
                          <NavLink
                            to="/collection-prediction-dashboard"
                            className="linksidebar"
                          >
                            {" "}
                            Collection Prediction
                            <br /> Dashboard
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.denialPredictionDashboard && (
                        <li>
                          <NavLink
                            to="/denial-prediction-dashboard"
                            className="linksidebar"
                          >
                            {" "}
                            Denial Prediction
                            <br /> Dashboard
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.overallPrediction && (
                        <li>
                          <NavLink
                            to="/denial-prediction"
                            className="linksidebar"
                          >
                            {" "}
                            Overall Prediction
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.AIAssistant && (
                        <li>
                          <NavLink to="/ai-bot" className="linksidebar">
                            AI Assistant
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                <li className="nav-item">
                  {role === "SuperAdmin" && (
                    <NavLink
                      className="nav-link"
                      to="/client-onboard"
                      activeClassName="active-nav-link"
                    >
                      {" "}
                      Client OnBoard
                    </NavLink>
                  )}
                </li>
                {roleAccessData.workqueue && (
                  <li className="nav-item">
                    <NavLink
                      to="/actions"
                      activeClassName="active-nav-link"
                      className="nav-link"
                    >
                      {" "}
                      Work Queue
                    </NavLink>
                  </li>
                )}
                {roleAccessData.allocation && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => toggleSubMenu("Menu Item 5")}
                    >
                      Allocation{" "}
                      {subMenuStates["Menu Item 5"] ? (
                        <span>&#9650;</span>
                      ) : (
                        <span>&#9660;</span>
                      )}
                    </a>
                    <ul
                      className={`dropdown-menu ${
                        subMenuStates["Menu Item 5"] ? "show" : ""
                      }`}
                    >
                      {roleAccessData.allocated && (
                        <li>
                          <NavLink
                            to="/allocate/allocated"
                            className="linksidebar"
                          >
                            {" "}
                            Allocated
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.unallocated && (
                        <li>
                          <NavLink
                            to="/allocate/unallocated"
                            className="linksidebar"
                          >
                            {" "}
                            UnAllocated
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.rejectionAndDenials && (
                        <li>
                          <NavLink
                            to="/allocate/rejection"
                            className="linksidebar"
                          >
                            {" "}
                            Rejections & Denials
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                {roleAccessData.configure && (
                  <li className="nav-item">
                    <NavLink
                      to="/configure/configure-list"
                      activeClassName="active-nav-link"
                      className="nav-link"
                    >
                      {" "}
                      Configure
                    </NavLink>
                  </li>
                )}
                {roleAccessData.userManagement && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => toggleSubMenu("Menu Item 6")}
                    >
                      User Management{" "}
                      {subMenuStates["Menu Item 6"] ? (
                        <span>&#9650;</span>
                      ) : (
                        <span>&#9660;</span>
                      )}
                    </a>
                    <ul
                      className={`dropdown-menu ${
                        subMenuStates["Menu Item 6"] ? "show" : ""
                      }`}
                    >
                      {roleAccessData.useronboard && (
                        <li>
                          <NavLink
                            to="/admin/useronboard"
                            className="linksidebar"
                          >
                            {" "}
                            Useronboard
                          </NavLink>
                        </li>
                      )}
                      <li>
                        {role === "SuperAdmin" && (
                          <NavLink
                            to="/admin/usersubscription"
                            className="linksidebar"
                          >
                            {" "}
                            User Subscription
                          </NavLink>
                        )}
                      </li>
                      {roleAccessData.role && (
                        <li>
                          <NavLink to="/roles" className="linksidebar">
                            {" "}
                            Roles
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                {roleAccessData.eligibility && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => toggleSubMenu("Menu Item 7")}
                    >
                      Eligibility{" "}
                      {subMenuStates["Menu Item 7"] ? (
                        <span>&#9650;</span>
                      ) : (
                        <span>&#9660;</span>
                      )}
                    </a>
                    <ul
                      className={`dropdown-menu ${
                        subMenuStates["Menu Item 7"] ? "show" : ""
                      }`}
                    >
                      {roleAccessData.eligibilityDataupload && (
                        <li>
                          <NavLink
                            to="/eligibility/Dataupload"
                            className="linksidebar"
                          >
                            {" "}
                            Data Upload
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.eligibilityTracker && (
                        <li>
                          <NavLink
                            to="/eligibility/Outcomedata"
                            className="linksidebar"
                          >
                            {" "}
                            Eligibility Tracker
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.eligibilityQuickcheck && (
                        <li>
                          <NavLink
                            to="/eligibility/Check"
                            className="linksidebar"
                          >
                            {" "}
                            Eligibility Quick Check
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.eligibilityUploadAuthorization && (
                        <li>
                          <NavLink
                            to="/eligibility/Auth"
                            className="linksidebar"
                          >
                            {" "}
                            Upload Authorization
                          </NavLink>
                        </li>
                      )}
                      {roleAccessData.eligibilityPriorauthTracker && (
                        <li>
                          <NavLink
                            to="/eligibility/PriorAuth"
                            className="linksidebar"
                          >
                            {" "}
                            Prior Auth Tracker
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                {roleAccessData.userPerformanceDashboard && (
                  <li className="nav-item">
                    <NavLink
                      to="/performances"
                      activeClassName="active-nav-link"
                      className="nav-link"
                    >
                      {" "}
                      Performances
                    </NavLink>
                  </li>
                )}
                {roleAccessData.completed && (
                  <li className="nav-item">
                    <NavLink
                      to="/user-completed-page"
                      activeClassName="active-nav-link"
                      className="nav-link"
                    >
                      {" "}
                      Completed
                    </NavLink>
                  </li>
                )}
                {roleAccessData.inprogress && (
                  <li className="nav-item">
                    <NavLink
                      to="/user-inprogress-page"
                      activeClassName="active-nav-link"
                      className="nav-link"
                    >
                      {" "}
                      Inprogress
                    </NavLink>
                  </li>
                )}
                {roleAccessData.denial && (
                  <li className="nav-item">
                    <NavLink
                      to="/user-denial-page"
                      activeClassName="active-nav-link"
                      className="nav-link"
                    >
                      {" "}
                      Denial
                    </NavLink>
                  </li>
                )}
                {roleAccessData.needToCall && (
                  <li className="nav-item">
                    <NavLink
                      to="/user-needtocall-page"
                      activeClassName="active-nav-link"
                      className="nav-link"
                    >
                      {" "}
                      Need To Call
                    </NavLink>
                  </li>
                )}
                {roleAccessData.pending && (
                  <li className="nav-item">
                    <NavLink
                      to="/user-nottouch-page"
                      activeClassName="active-nav-link"
                      className="nav-link"
                    >
                      {" "}
                      Pending
                    </NavLink>
                  </li>
                )}
                {roleAccessData.userWorkqueue && (
                  <li className="nav-item">
                    <NavLink
                      to="/actions"
                      activeClassName="active-nav-link"
                      className="nav-link"
                    >
                      {" "}
                      Work Queue
                    </NavLink>
                  </li>
                )}
                {roleAccessData.userRejectionAndDenials && (
                  <li className="nav-item">
                    <NavLink
                      to="/action-rejection-denials"
                      activeClassName="active-nav-link"
                      className="nav-link"
                    >
                      {" "}
                      Rejection & Denials
                    </NavLink>
                  </li>
                )}
                {claimtracker ? (
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        subMenuStates["Menu Item 8"] ? "active-nav-link" : ""
                      }`}
                      onClick={() => toggleSubMenu("Menu Item 8")}
                    >
                      Claim Tracker
                      {subMenuStates["Menu Item 8"] ? (
                        <span>&#9650;</span>
                      ) : (
                        <span>&#9660;</span>
                      )}
                    </a>
                    <ul
                      className={`dropdown-menu ${
                        subMenuStates["Menu Item 8"] ? "show" : ""
                      }`}
                    >
                      <li>
                        <NavLink
                          to="/open-claims"
                          className="linksidebar dropdown-item"
                        >
                          Open Claims
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/closed-claims"
                          className="linksidebar dropdown-item"
                        >
                          Closed Claims
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                ) : null}
                <div
                  className="ml-auto d-flex align-items-center"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <li className="nav-item dropdown2">
                    <div className="dropdown2">
                      <div
                        className="btn  dropdown-toggle2"
                        onClick={ToggleSidebar}
                      >
                        <BiUser
                          style={{
                            fontSize: "25px",
                          }}
                        />
                      </div>
                      <div className="dropdown-content2">
                        <p className="text-animation">
                          Hi,
                          <br /> {username}
                        </p>
                        <div className="avatar-upload">
                          <div className="avatar-edit">
                            <input
                              type="file"
                              id="imageUpload"
                              accept=".png, .jpg, .jpeg"
                            />
                            <label for="imageUpload"></label>
                          </div>
                          <div className="avatar-preview">
                            <div id="imagePreview"></div>
                          </div>
                        </div>
                        <br />
                        <div className="profile-container">
                          <div className="profile-item">
                            <IoIdCardOutline />
                            <span className="text-animation">{userid}</span>
                          </div>
                          <div className="profile-item">
                            <BsPhone />
                            <span className="text-animation">{phone}</span>
                          </div>
                          <div className="profile-item">
                            <AiOutlineMail />
                            <span className="text-animation">{Email}</span>
                          </div>
                          <div className="profile-item">
                            <AiOutlineTeam />
                            <span className="text-animation">{role}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <button
                      style={{
                        border: "none",
                        background: "#041c3c",
                        padding: "6px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <a href="/logout" style={{ textDecoration: "none" }}>
                        <BiLogOut className="logout-icon" />
                        <span style={{ color: "white" }}>Log Out</span>
                      </a>
                    </button>
                  </li>{" "}
                  &nbsp;
                </div>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
      <div className="title-page">
        <p className="content">
          {name && <span>{name}</span>}
          {children && <span>{children}</span>}
        </p>
        <div className="client-info">
          {role !== "Team Member" ? (
            <button onClick={toggleCard} className="toggle-button">
              Upload %
            </button>
          ) : null}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <p className="content">Client Name:&nbsp;{clientname}</p>
        </div>

        <div className={`sliding-card ${isVisible ? "visible" : ""}`}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h6 style={{ color: "white" }}>Upload Status</h6>
            <button onClick={toggleCard} className="close-button">
              <IoIosClose style={{ fontSize: "25px" }} />
            </button>
          </div>
          <hr className="custom-hr" />
          <p>
            <span className="font_slider">File Name:</span> {fileName}
          </p>
          <div className="card_slider">
            <div className="progress-bg">
              <div
                className="progress-bar1"
                style={{ width: `${progress}%` }}
              />
              <p style={{ color: "black" }}>{progress}%</p>
            </div>
          </div>

          <div>
            <table className="table-bordered">
              <tbody>
                <tr>
                  <td className="font_slider">Total Count</td>
                  <td>{totalCount}</td>
                </tr>
                <tr>
                  <td className="font_slider">Inserted</td>
                  <td>{inserted}</td>
                </tr>
                <tr>
                  <td className="font_slider">Not Inserted</td>
                  <td>{notInserted}</td>
                </tr>
                <tr>
                  <td className="font_slider">Duplicate</td>
                  <td>{duplicate}</td>
                </tr>
                <tr>
                  <td className="font_slider">queuing</td>
                  <td>{queuing}</td>
                </tr>
                <tr>
                  <td className="font_slider">Processed Count</td>
                  <td>{progressCount}</td>
                </tr>
                <tr>
                  <td className="font_slider">Duration</td>
                  <td>{duration} seconds</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Newsidebar;
