import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import { ip } from ".././Api";
import axios from "axios";
import { Link } from "react-router-dom";
import fileformat from "../../Files/Upload_Rejection.xlsx";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import upload from "../../Images/upload.png";
import excel from "../../Images/excel.png";
import { IoMdClose } from "react-icons/io";
export default class RejectionUpload extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();

    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),     
      file: null,
      client: sessionStorage.getItem("clientid"),
      offset: 0,
      currentpage: 0,
      perPage: 10,
      filedata:[]
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.clearFile = this.clearFile.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getDetails();
      }
    );
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };
  clearFile() {
    this.setState({ file: null });
  }
  onFileUpload = async (e) => {
    if (!this.state.file) {
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    this.setState({
      fileupload: true,
    });
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);
    value.append("clientids", this.state.client);
    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    await axios({
      method: "post",
      url: `${ip}/organizationdetails/bulk/upload`,
      data: value,
      headers: headers,
    })
      .then((response) => {
        //handle success
        if (response.status === 200) {
          toast.success("File uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          this.getclaims();
        }
        e.target.value = "";
      })
      .catch((err) => {
        //handle error
        // this.setState({ fileUploaded: !this.state.fileUploaded });
        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error("Please include all the fields in excel data", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  toggleUpload = () => {
    const { uploaddetails } = this.state;
    this.setState({
      uploaddetails : !uploaddetails,
      file: null,
    });
  };
  getDetails = async () => {
  //api call to get all cpt data
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${this.state.accesstoken}`,
  };
  // eslint-disable-next-line
  const res = await axios
    .get(`${ip}/organizationdetails/getorganizationname?clientId=${this.state.client}&pageno=${this.state.currentpage}`, {
      headers: headers,
    })
    .then((res) => {
      this.setState({
        pageCount: Math.ceil(res.data.count / this.state.perPage),
        filedata: res.data.organizationdetails,
      });
    })
    .catch((err) => {});
  }
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getDetails();
  }
  render() {
    return (
      <div className="align-row" style={{overflow:"hidden"}}>
        <Newsidebar name="Provider Details" />
        <ToastContainer></ToastContainer>
        <div className="align-column">
          <br />
          <div class="btn-group" role="group" style={{marginLeft:"70%", marginRight:"10%"}}>
            <button
              type="button"
              class="btn"
              style={{ backgroundColor: "#7DD657", color: "#fff" }}
              onClick={this.toggleUpload}
            >
              upload file
            </button>
          </div>
          <div style={{marginLeft:"3%"}}>
          <ReactPaginate
          previousLabel={<IoIosArrowBack />}
          nextLabel={<IoIosArrowForward />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={this.state.currentpage}
        />
        </div>
          <div style={{ marginLeft: "3%", marginRight: "5%" }}>
                      <Table bordered>
                        <thead style={{background:"#041c3c", color:"white", border:"1.5px solid black"}}>
                          <tr>
                            <td>Location</td>
                            <td>Provider Code</td>
                            <td>Provider Name</td>
                            <td>NPI</td>
                            <td>Organization Name</td>
                            <td>TaxID</td>
                          </tr>
                        </thead>
                        <tbody style={{border:"1.5px solid black"}}>
                          {this.state.filedata &&
                            this.state.filedata.map((data, index) => (
                              <tr key={data._id} style={{border:"1.5px solid black"}}>
                                <td>{data.Location}</td>
                                <td>{data.ProviderCode}</td>
                                <td>{data.ProviderName}</td>
                                <td>{data.Npi}</td>
                                <td>{data.OrganizationName}</td>
                                <td>{data.TaxID}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>              
        </div>
        <Modal
        centered
        isOpen={this.state.uploaddetails}
        toggle={this.toggleUpload}
      >
        <ModalHeader
          toggle={this.toggleUpload}
          style={{ fontWeight: "700", fontSize: "30px" }}
        >
          Bulk Allocate
        </ModalHeader>
        <ModalBody>
          <button
            type="button"
            class="btn"
            style={{ backgroundColor: "#041c3c", color: "#fff" }}
          >
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to={fileformat}
              download="Sample_Provider_Details.xlsx"
              target="_blank"
            >
              {" "}
              Sample File
            </Link>
          </button>
          <input
          type="file"
          id="file"
          ref={this.fileInput}
          onChange={this.onFileChange}
          style={{ display: "none" }}
          accept=".xlsx"
        />
        {this.state.file ? (
          <div className="file-card-upload">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                flexDirection: "column",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <div className="file-card" style={{ position: "relative" }}>
                  <span>
                    <img
                      src={excel}
                      alt="excel"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </span>
                  <div className="file-info">
                    <div style={{ flex: 1 }}>
                      <h6>{this.state.file.name}</h6>
                    </div>
                  </div>
                  <button
                    className="close-icon"
                    onClick={this.clearFile} // Clear file function
                  >
                    <IoMdClose />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="file-btn"
            onClick={() => this.fileInput.current.click()}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <img
                src={upload}
                alt="upload"
                style={{ width: "90px", height: "90px" }}
              />
              <div>
                <p style={{ margin: 0 }}>Click file to this area to upload</p>
                <span style={{ color: "GrayText", fontSize: "11px" }}>
                  The given input should be in .xlsx format
                </span>
              </div>
            </div>
          </div>
        )}
        </ModalBody>
        <ModalFooter>
          <div className="multi-button">
            <Button
              className="reunalloc"
              onClick={(e) => {
                this.onFileUpload(e);
              }}
            >
              Upload
            </Button>
            <Button
              className="reunalloc"
              onClick={() => {
                this.toggleUpload();
              }}
            >
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      </div>
    );
  }
}
