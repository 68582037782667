import React, { createContext, useContext, useState, useEffect } from "react";
import { io } from "socket.io-client";
import { ip } from "../Api";

const SocketContext = createContext();

// Custom hook to use socket
export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [notInserted, setNotInserted] = useState(0);
  const [inserted, setInserted] = useState(0);
  const [duplicate, setDuplicate] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [status, setStatus] = useState("");
  const [fileName, setFileName] = useState("");
  const [queuing, setQueuing] = useState(0);
  const [progressCount, setProgressCount] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("select");
  const [duration, setDuration] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileMeta, setFileMeta] = useState(null); // Store file metadata (name, size)

  // Retrieve clientId and roomId from session storage
  const clientId = parseInt(sessionStorage.getItem("clientid"));

  // Toggle visibility for the progress card
  const toggleCard = () => {
    setIsVisible(!isVisible);
  };

  // Initialize the socket connection and set up event listeners
  useEffect(() => {
    const newSocket = io(ip, {
      withCredentials: true,
      transports: ["websocket"],
      secure: true,
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });
    newSocket.on("connect", () => {
      console.log("Socket connected:", newSocket.id);
    });

    newSocket.on("connect_error", (error) => {
      console.error("Connection error:", error);
    });
    setSocket(newSocket);

    return () => {
      newSocket.close(); // Close the socket when the component unmounts
    };
  }, []);
  // Listen to socket events for upload progress and completion
  // Register clientId and roomId on socket connection
  useEffect(() => {
    if (socket && clientId) {
      //  const client = clientId.toString();
      // console.log("client id type", typeof clientId);
      socket.emit("registerClient", { clientId });
      console.log("Client registered context:", { clientId });
      console.log(
        "Setting up event listeners for upload progress and completion."
      );

      socket.on("uploadProgress", (data) => {
        console.log("Received upload progress:", data); // Ensure this logs the expected structure
        if (data.clientId === clientId) {
          setProgress(data.percentage);
          setNotInserted(data.notinserted);
          setInserted(data.inserted);
          setDuplicate(data.duplicate);
          setTotalCount(data.totalRows);
          setStatus(data.status);
          setFileName(data.filename);
          setQueuing(data.queuing);
          setProgressCount(data.ProcessedCount_TotalRecords);
          setUploadStatus("uploading");
          setIsCardVisible(true);
          // Fallback for fileMeta in case the backend misses the type
          const fileMeta = {
            name: data.filename,
            size: data.size || 0, // Default to 0 if size is not provided
            type: data.type || "application/octet-stream", // Default MIME type if not provided
          };

          setFileMeta(fileMeta); // Set fileMeta with fallback
           // Save the clientId along with fileMeta in sessionStorage
          sessionStorage.setItem("fileMeta", JSON.stringify({ ...fileMeta, clientId }));
          sessionStorage.setItem("uploadStatus", uploadStatus);
        } else {
          console.error("Invalid upload progress data:", data);
        }
      });

      socket.on("uploadComplete", (data) => {
        console.log("Upload complete client id:", data); // Log upload completion
        if (data.clientId === clientId) {
          setDuration(data.uploadTime);
          setUploadStatus("done");
          setIsCardVisible(false);
          sessionStorage.setItem("uploadStatus", "done"); // Persist the upload status in sessionStorage
        } else {
          console.error("Invalid upload complete data:", data);
        }
      });

      return () => {
        socket.off("uploadProgress");
        socket.off("uploadComplete");
      };
    } else if (socket && roomId) {
      console.log(
        "Setting up event listeners for upload progress and completion."
      );
      socket.on("uploadProgress", (data) => {
        console.log("Received upload progress:", data); // Ensure this logs the expected structure
        if (data.roomId === roomId) {
          setProgress(data.percentage);
          setNotInserted(data.notinserted);
          setInserted(data.inserted);
          setDuplicate(data.duplicate);
          setTotalCount(data.totalRows);
          setStatus(data.status);
          setFileName(data.filename);
          setQueuing(data.queuing);
          setProgressCount(data.ProcessedCount_TotalRecords);
        } else {
          console.error("Invalid upload progress data:", data);
        }
      });

      socket.on("uploadComplete", (data) => {
        console.log("Upload complete roomID:", data); // Log upload completion
        if (data.roomId === roomId) {
          setDuration(data.uploadTime);
          setUploadStatus("done");
          sessionStorage.setItem("uploadStatus", "done"); // Persist the upload status in sessionStorage
        } else {
          console.error("Invalid upload complete data:", data);
        }
      });

      return () => {
        socket.off("uploadProgress");
        socket.off("uploadComplete");
      };
    }
    return () => {
      if (socket && roomId) {
        socket.emit("leaveRoom", roomId); // Leave the room on cleanup
        console.log(`Left room: ${roomId}`);
      }
    };
  }, [socket, clientId, roomId]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        progress,
        setProgress,
        notInserted,
        setNotInserted,
        inserted,
        setInserted,
        duplicate,
        setDuplicate,
        totalCount,
        setTotalCount,
        status,
        setStatus,
        fileName,
        setFileName,
        queuing,
        setQueuing,
        progressCount,
        setProgressCount,
        uploadStatus,
        setUploadStatus,
        duration,
        setDuration,
        toggleCard,
        isVisible,
        roomId,
        setRoomId,
        isCardVisible,
        setIsCardVisible,
        fileMeta,
        setFileMeta,
        setSelectedFile,
        selectedFile,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
